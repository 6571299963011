import { useGetHomevisualsQuery } from "./homevisualsApiSlice"
import Homevisual from "./Homevisual"
import useAuth from "../../../hooks/useAuth"
import useTitle from "../../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const HomevisualsList = () => {
    useTitle('techHomevisuals: Homevisuals List')

    const { username, isManager, isAdmin } = useAuth()

    const {
        data: homevisuals,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetHomevisualsQuery('homevisualsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = homevisuals

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(homevisualId => entities[homevisualId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map((homevisualId, index) => <Homevisual key={homevisualId} homevisualId={homevisualId} index={index} />)

        content = (
            <table className="table table--homevisuals">
                <thead className="table__thead">
                    <tr>
                        <th scope="col" className="table__th homevisual__title">No</th>
                        <th scope="col" className="table__th homevisual__title">View</th>
                        <th scope="col" className="table__th homevisual__title">Title</th>
                        <th scope="col" className="table__th homevisual__title">Path</th>
                        <th scope="col" className="table__th homevisual__status">Username</th>
                        <th scope="col" className="table__th homevisual__created">Created</th>
                        <th scope="col" className="table__th homevisual__updated">Updated</th>
                        <th scope="col" className="table__th homevisual__username">Owner</th>
                        <th scope="col" className="table__th homevisual__edit">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
        )
    }

    return content
}
export default HomevisualsList