import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewSj62renovationMutation } from "./sj62renovationsApiSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"

const NewSj62renovationForm = ({ users }) => {

    const [addNewSj62renovation, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewSj62renovationMutation()

    const navigate = useNavigate()

    const [task, setTask] = useState("")
    const [priority, setPriority] = useState("urgent")
    const [itemtype, setItemtype] = useState("unassigned")
    const [description, setDescription] = useState("")
    const [status, setStatus] = useState("new")
    const [cost, setCost] = useState(0)
    const [budget, setBudget] = useState(0)
    const [paid, setPaid] = useState(false)
    const [vendor, setVendor] = useState("unassigned")
    const [material, setMaterial] = useState("")
    const [area, setArea] = useState("unassigned")
    const [productcode, setProductcode] = useState("0000000000")
    const [qty, setQty] = useState(1)
    const [unitcost, setUnitcost] = useState(0)
    const [completed, setCompleted] = useState(false)
    // const [userId, setUserId] = useState(users[0].id)
    const [userId, setUserId] = useState("653f54be13d7ab2243a410f2") //force to admin

    useEffect(() => {
        if (isSuccess) {
            setTask('')
            setDescription('')
            setUserId('')
            navigate('/dash/sj62renovations')
        }
    }, [isSuccess, navigate])

    const onTaskChanged = e => setTask(e.target.value)
    const onPriorityChanged = e => setPriority(e.target.value)
    const onItemtypeChanged = e => setItemtype(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)
    const onStatusChanged = e => setStatus(e.target.value)
    const onCostChanged = e => setCost(e.target.value)
    const onBudgetChanged = e => setBudget(e.target.value)
    const onPaidChanged = e => setPaid(prev => !prev)
    const onVendorChanged = e => setVendor(e.target.value)
    const onMaterialChanged = e => setMaterial(e.target.value)
    const onAreaChanged = e => setArea(e.target.value)
    const onProductcodeChanged = e => setProductcode(e.target.value)
    const onQtyChanged = e => setQty(e.target.value)
    const onUnitcostChanged = e => setUnitcost(e.target.value)
    const onCompletedChanged = e => setCompleted(prev => !prev)
    const onUserIdChanged = e => setUserId(e.target.value)

    const canSave = [task, description, userId].every(Boolean) && !isLoading

    const onSaveSj62renovationClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewSj62renovation({ user: userId, task, priority, itemtype, description, status, cost, budget, paid, vendor, material, area, productcode, qty, unitcost, completed })
        }
    }

    const options = users.map(user => {
        return (
            <option
                key={user.id}
                value={user.id}
            > {user.username}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validTitleClass = !task ? "form__input--incomplete" : ''
    const validTextClass = !description ? "form__input--incomplete" : ''

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveSj62renovationClicked}>
                <div className="border border-gray-400 bg-gray-200 border-4 flex flex-cols rounded-md justify-between p-2">
                    <h2 className="font-bold text-2xl text-gray-600">New Sj62renovation</h2>
                    <div className="form__action-buttons">
                        <button
                            className="bg-blue-400 p-2 rounded-md text-blue-800 font-bold"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} /> Save
                        </button>
                    </div>
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="title">
                        Task:</label>
                    <input
                        className="w-full p-2 rounded-md"
                        id="title"
                        name="title"
                        type="text"
                        autoComplete="off"
                        value={task}
                        onChange={onTaskChanged}
                    />
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">
                        Description:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass}`}
                        rows={5}
                        id="text"
                        name="text"
                        value={description}
                        onChange={onDescriptionChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >priority: {priority}</label>
                    <select value={priority} onChange={onPriorityChanged} className="w-1/6">
                        <option value="phase 1">phase 1</option>
                        <option value="phase 2">phase 2</option>
                        <option value="cancel">cancel</option>
                        <option value="urgent">urgent</option>
                        <option value="normal">normal</option>
                        <option value="postponed">postponed</option>
                    </select>
                </div>
                
                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >itemtype: {itemtype}</label>
                    <select value={itemtype} onChange={onItemtypeChanged} className="w-150">
                        <option value="furniture">furniture</option>
                        <option value="electronic">electronic</option>
                        <option value="sipil">sipil</option>
                        <option value="unassigned">unassigned</option>
                    </select>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        status:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="text"
                        type="text"
                        name="text"
                        value={status}
                        onChange={onStatusChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        budget:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="budget"
                        value={budget}
                        onChange={onBudgetChanged}
                    />
                </div>
                
                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label >
                        cost:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="text"
                        type="text"
                        name="cost"
                        value={cost}
                        onChange={onCostChanged}
                    />
                </div>


                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">vendor:{vendor}</label>
                    <select value={vendor} onChange={onVendorChanged} className="w-150">
                        <option value="ikea">IKEA</option>
                        <option value="munir">MUNIR</option>
                        <option value="parno">Parno</option>
                        <option value="uus">Uus</option>
                        <option value="mitra10">Mitra10</option>
                        <option value="azko">azko</option>
                        <option value="online">Online</option>
                        <option value="unassigned">unassigned</option>
                    </select>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label" htmlFor="text">
                        material:</label>
                    <textarea
                        className={`form__input form__input--text ${validTextClass}`}
                        rows={5}
                        id="text"
                        name="text"
                        value={material}
                        onChange={onMaterialChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>area: {area}</label>
                    <select value={area} onChange={onAreaChanged} className="w-1/6">
                        <option value="unassigned">unassigned</option>
                        <option value="ruang tamu">1. ruang tamu</option>
                        <option value="ruang tengah">2. ruang tengah</option>
                        <option value="pantry">3. pantry</option>
                        <option value="kamar anak laki">4. kamar anak laki</option>
                        <option value="kamar mandi anak laki">5. kamar mandi anak laki</option>
                        <option value="outdoor">6. outdoor</option>
                        <option value="dapur kotor">7. dapur kotor</option>
                        <option value="laundry">8. laundry</option>
                        <option value="kamar art">9. kamar art</option>
                        <option value="kamar mandi art">10. kamar mandi art</option>
                        <option value="tangga">11. tangga</option>
                        <option value="ruang santai">12. ruang santai</option>
                        <option value="kamar anak perempuan">13. kamar anak perempuan</option>
                        <option value="kamar mandi anak perempuan">14. kamar mandi anak perempuan</option>
                        <option value="kamar utama">15. kamar utama</option>
                        <option value="kamar wardrobe">16. kamar wardrobe</option>
                        <option value="kamar mandi kamar utama">17. kamar mandi kamar utama</option>
                        <option value="carport">18. carport</option>
                        <option value="utilities">19. utilities</option>
                    </select>
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        productcode:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={productcode}
                        onChange={onProductcodeChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        qty:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={qty}
                        onChange={onQtyChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label>
                        unitcost:</label>
                    <input
                        className={`form__input form__input--text ${validTextClass}`}
                        id="sj62renovation-text"
                        type="text"
                        name="cost"
                        value={unitcost}
                        onChange={onUnitcostChanged}
                    />
                </div>

                <div  className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                <label className="" htmlFor="sj62renovation-completed">
                            WORK COMPLETE:
                            <input
                                className="form__checkbox"
                                id="sj62renovation-completed"
                                name="completed"
                                type="checkbox"
                                checked={completed}
                                onChange={onCompletedChanged}
                            />
                        </label>

                        <label className="ml-5" htmlFor="sj62renovation-completed">
                            Paid:
                            <input
                                className="form__checkbox"
                                id="sj62renovation-completed"
                                name="completed"
                                type="checkbox"
                                checked={paid}
                                onChange={onPaidChanged}
                            />
                        </label>
                </div>

                <div className="flex flex-col mt-5 border border-gray-400 p-2 rounded-md border-t-4">
                    <label className="form__label form__checkbox-container" htmlFor="username">
                        ASSIGNED TO:</label>
                    <select
                        id="username"
                        name="username"
                        className="form__select"
                        value={userId}
                        onChange={onUserIdChanged}
                    >
                        {options}
                    </select>
                </div>
            </form>
        </>
    )

    return content
}

export default NewSj62renovationForm